import type {
	VersionNumberString,
	VersionObject,
} from '../../../../types.d';
import React, { useState } from 'react';
import ClipboardIcon from '../../../../assets/img/icons/clipboard.svg';


export interface StarterProps {
	initialVersion: VersionObject
	software: {
		gitProjectName: string
		gitTags: Record<VersionNumberString,string>
		name: string
		newestVersion: VersionObject
		type: 'starter'
		versions: VersionObject[]
	}
	commands: {
		initProjectCommands: {
			bash: string
			batch: string
		}
	}
}


export default function Starter({
	initialVersion,
	software,
	commands
}: StarterProps) {
	const [selectedVersion, setSelectedVersion] = useState(initialVersion);

	function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
		const selectedVersionNumber = e.target.value;
		setSelectedVersion(prev => {
			const maybeVersionObject = software.versions.find((version) => version.versionNumber === selectedVersionNumber);
			return maybeVersionObject || prev;
		});
	}

	function copyToClipboard(inputID: string) {
		const element = document.getElementById(inputID) as HTMLInputElement;

		const value = element ? element.value : '';

		if (value) {
			navigator.clipboard.writeText(value);
			element.focus();
		}
	}

	function isSupportedVersionGreaterThan(supportedVersions: VersionNumberString[], majorVersionNumber: number) {
		const versions = ([] as VersionNumberString[]).concat(supportedVersions);

		if (versions.length > 1) {
			return versions.every((version) => parseInt(version.split('.')[0]) >= majorVersionNumber);
		}

		if (versions.length === 1) {
			return parseInt(versions[0].split('.')[0]) >= majorVersionNumber;
		}

		return false;
	}

	function displaySupportedVersions(supportedVersions: VersionNumberString[]) {
		const versions = ([] as VersionNumberString[]).concat(supportedVersions);

		if (versions.length > 1) {
			return versions.map((v, i) => `${i > 0 ? ', ' : ''} ${v}`);
		}

		if (versions.length === 1) {
			return versions[0];
		}

		return '';
	}

	function getInstallCommand() {
		let command = `enonic project create -r ${software.gitProjectName}`;
		if (selectedVersion.versionNumber !== initialVersion.versionNumber && software.gitTags[selectedVersion.versionNumber]) {
			command += ` -c ${software.gitTags[selectedVersion.versionNumber]}`;
		}
		return command;
	}

	return (
		<div className="software-show__download-instructions">
		<h2 className="software-show__download-instructions-heading">
			{`Clone ${software.name}`}
		</h2>

		{ software.versions && (
			<select
				className="software-show__version-select"
				onChange={handleSelect}
			>
			{
				software.versions.map((version) => (
					<option value={version.versionNumber} selected={selectedVersion.versionNumber === version.versionNumber}>
					v.
					{version.versionNumber}
					{version.versionNumber === software.newestVersion.versionNumber ? ' - latest' : '' }
					</option>
				))
			}
			</select>
		)}

		{ isSupportedVersionGreaterThan(selectedVersion.supportedVersions, 7) && (
			<div className="software-show__download-instructions-body versionSevenCloneMsg">
				<p>
					This version requires XP
					{' '}
					<span className="requiredVersion">{displaySupportedVersions(selectedVersion.supportedVersions)}</span>
					{' '}
					or above.
				</p>

				<h3 style={{ fontSize: '2.2rem', fontWeight: 100 }}>
					Use
					{' '}
					<a href="https://developer.enonic.com/start">Enonic CLI</a>
					{' '}
					to set up your project.
				</h3>

				<p>Run the following command:</p>

				<div className="software-show__toolbox-code software-show__toolbox-code">
					<h3 className="software-show__toolbox-code-heading">$</h3>
					<div className="software-show__toolbox-code-command">
					<input id="software-show__toolbox-code-command-input--starter-seven" className="software-show__toolbox-code-command-input" value={getInstallCommand()} />
					</div>
				</div>

				<p>
					<a href="https://developer.enonic.com/docs/enonic-cli/master">Enonic CLI documentation</a>
				</p>
			</div>
		)}

		{ !isSupportedVersionGreaterThan(selectedVersion.supportedVersions, 7) && (
			<div className="software-show__download-instructions-body notVersionSevenCloneMsg">
				<p>
					This version requires XP
					{' '}
					<span className="requiredVersion">{displaySupportedVersions(selectedVersion.supportedVersions)}</span>
				</p>

				<p>
					To initialize a new project based on
					{' '}
					<span>{software.name}</span>
					, execute the command below.
					Remember to replace
					{' '}
					<strong>&lt;com.company.myapp&gt;</strong>
					{' '}
					with a unique name for your app, and
					{' '}
					<strong>&lt;/my/projects&gt;</strong>
					{' '}
					with the location you want to store the project.
					<strong>&lt;$XP_INSTALL&gt;</strong>
					{' '}
					is the location of your XP installation.
				</p>

				<div className="software-show__toolbox-code software-show__toolbox-code--bash">
					<h3 className="software-show__toolbox-code-heading">OSX/Linux</h3>
					<div className="software-show__toolbox-code-command">
						<input
							className="software-show__toolbox-code-command-input"
							id="software-show__toolbox-code-command-input--starter-bash"
							value={commands.initProjectCommands.bash}
						/>
					</div>
					<div className="software-show__toolbox-code-clipboard-btn-container">
					<button
						type="button"
						className="software-show__toolbox-code-clipboard-btn"
						onClick={() => copyToClipboard('software-show__toolbox-code-command-input--starter-bash')}
					>
						<div className="software-show__toolbox-code-clipboard-btn-icon">
						<ClipboardIcon viewBox="0 0 1792 1792" />
						</div>
						<span>Copy</span>
					</button>
					</div>
				</div>

				<div className="software-show__toolbox-code software-show__toolbox-code--bash">
					<h3 className="software-show__toolbox-code-heading">Windows</h3>
					<div className="software-show__toolbox-code-command">
						<input
							className="software-show__toolbox-code-command-input"
							id="software-show__toolbox-code-command-input--starter-batch"
							value={commands.initProjectCommands.batch}
						/>
					</div>
					<div className="software-show__toolbox-code-clipboard-btn-container">
					<button
						type="button"
						className="software-show__toolbox-code-clipboard-btn"
						onClick={() => copyToClipboard('software-show__toolbox-code-command-input--starter-batch')}
					>
						<div className="software-show__toolbox-code-clipboard-btn-icon">
						<ClipboardIcon viewBox="0 0 1792 1792" />
						</div>
						<span>Copy</span>
					</button>
					</div>
				</div>

				<p>
					The init-project tool initializes a new application project structure by retrieving a Git repository,
					removing all references to the Git repository, and adapting its build file properties
					(gradle.properties).
				</p>

				<p>
					<a href="http://xp.readthedocs.org/en/latest/reference/toolbox/init-project.html">
					Complete init-project documentation
					</a>
				</p>
			</div>
		)}
		</div>
	);
}
