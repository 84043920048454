import type { VersionObject } from '../../../../types.d';
import React, { useState } from 'react';
import ArrowIcon from '../../../../assets/img/icons/arrow.svg';
import DownloadInstructions, { type DownloadInstructionsProps } from '../DownloadInstructions/DownloadInstructions';
import Modal from '../../Modal';


export interface HeaderProps {
	header: {
		software: {
			description?: string
			name?: string
			icon: {
				alt: string
				url: string
			}
			type: string
		}
	}
	selectedVersion: VersionObject
	downloadInstructions: DownloadInstructionsProps
}


export default function Header({
	downloadInstructions,
	header: {
		software: {
			description,
			name,
			icon: {
				alt,
				url
			},
			type
		}
	},
	selectedVersion,
}: HeaderProps) {
	const [showModal, setShowModal] = useState(false);
	const [modalBody, setModalBody] = useState<JSX.Element>();

	function handleGetStartedClick() {
		setModalBody(
			<DownloadInstructions {...{
				initialVersion: selectedVersion,
				...downloadInstructions
			}} />,
		);
		setShowModal(true);
	}

	return (
		<>
			<header className="software-show__header">
				<>
					{ url && <img className="software-show__software-icon" src={url} alt={alt} /> }
					{ type && selectedVersion && (
						<button
							type="button"
							className={`software-show__download-button software-show__download-button--${type}`}
							onClick={handleGetStartedClick}
						>
							<ArrowIcon className="software-show__download-button-icon" viewBox="0 0 400 400" />
							<span>Get started…</span>
						</button>
					)}
					{ name && <h1 className="software-show__heading">{ name }</h1> }
					{ description && <p className="software-show__subtitle">{ description }</p> }
				</>
			</header>

			<Modal show={showModal} close={() => setShowModal(false)}>{modalBody}</Modal>
		</>
	);
}
