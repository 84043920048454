import type { MenuItem, MenuProps } from "../../../types.d";
import React from "react";
import { useId, useState } from "react";


export default function Menu({ menuItems }: MenuProps) {
	const [hoveredIndex, setHoveredIndex] = useState(-1);

	function _getClass(item: MenuItem, className: string) {
		return item.isActive || item.inPath ? `${className} active` : className;
	}

	const subMenuItemKeyPrefix = useId();
	function _getMenuItemHTML(menuItem: MenuItem, parentKey: string) {
		if (menuItem.hasChildren) {
		return (
			<>
			<button type="button" className="page-default__dropdown-menu-link">
				{menuItem.title}
			</button>
			<ul className="page-default__dropdown-menu-list">
				{menuItem.children?.map((submenuItem, i) => (
					<li key={`${parentKey}-${subMenuItemKeyPrefix}-${String(i) /* using index is not ideal */}`}>
						<a className="dropdown-link" href={submenuItem.url}>
							{submenuItem.title}
						</a>
					</li>
				))}
			</ul>
			</>
		);
		}

		return (
			<a
				className={_getClass(menuItem, "page-default__dropdown-menu-link")}
				href={menuItem.url}
			>
				{menuItem.title}
			</a>
		);
	}

	const menuItemKeyPrefix = useId();
	return (
		<ul className="page-default__dropdown-menu">
			{menuItems.map((menuItem, index) => {
				const key = `${menuItemKeyPrefix}-${String(index) /* using index is not ideal */}`;
				return (
				<li
					className={`page-default__dropdown-menu-group${
						hoveredIndex === index ? " open" : ""
					}`}
					key={key}
					onMouseEnter={() => setHoveredIndex(index)}
					onMouseLeave={() => setHoveredIndex(-1)}
				>
					{_getMenuItemHTML(menuItem, key)}
				</li>
				);
			})}
		</ul>
	);
}
