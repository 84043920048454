import React, { useState } from 'react';

export default function () {
  const [hovered, setHovered] = useState(false);

  return (
    <ul className="page-default__dropdown-menu site">
      <li
        className={`page-default__dropdown-menu-group ${hovered ? ' open' : ''}`}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <button type="button" className="page-default__dropdown-menu-link important">
          Our sites
        </button>
        <ul className="page-default__dropdown-menu-list">
          <li className="custom-links">
            <a className="dropdown-link" target="_blank" href="https://enonic.com" rel="noreferrer">
              Enonic Homepage
              <span className="external" title="external" />
            </a>
            <a className="dropdown-link" target="_blank" href="https://developer.enonic.com" rel="noreferrer">
              Developer Portal
              <span className="external" title="external" />
            </a>
            <a className="dropdown-link" target="_blank" href="https://discuss.enonic.com/" rel="noreferrer">
              Community Forum
              <span className="external" title="external" />
            </a>
            <a className="dropdown-link" target="_blank" href="https://support.enonic.com" rel="noreferrer">
              Support
              <span className="external" title="external" />
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
}
