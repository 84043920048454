import React, { useEffect, useRef } from 'react';


interface ModalProps {
	children?: JSX.Element
	close: () => void
	maxWidth?: number|string
	show: boolean
}


export default function Modal({
	children,
	close,
	maxWidth,
	show,
}: ModalProps) {
	const ref = useRef(null);

	// EFFECTS
	useEffect(() => {
		const handleEsc = (e: KeyboardEvent) => {
			// eslint-disable-next-line no-unused-expressions
			e.code === 'Escape' && close();
		};

		const handleClickOutside = (e: MouseEvent) => {
			if ((e.target as HTMLDivElement).classList.contains('modal--active')) {
				close();
			}
		};

		document.addEventListener('keydown', handleEsc);
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('keydown', handleEsc);
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	// HELPER FUNCTIONS
	function getClassName() {
		return `modal${show ? ' modal--active' : ''}`;
	}

	function getStyle() {
		return { visibility: (show ? 'visible' : 'hidden') as React.CSSProperties['visibility'] };
	}

	return (
		<div ref={ref} className={getClassName()} style={getStyle()}>
			<div className="modal__container" style={maxWidth ? { maxWidth } : {}}>
				<button type="button" className="modal__btn" onClick={() => close()}>
					<span className="modal__btn-text">Close</span>
				</button>
				<div className="modal__content">
					{ children }
				</div>
			</div>
		</div>
	);
}
