import React from 'react';
import Application, { type ApplicationProps } from './Application';
import Starter, { type StarterProps } from './Starter';
import Library, { type LibraryProps } from './Library';


export type DownloadInstructionsProps = ApplicationProps | StarterProps | LibraryProps;


export default function DownloadInstructions(props: DownloadInstructionsProps) {
	if (props.software.type === 'application') { return <Application {...props as ApplicationProps} />; }
	if (props.software.type === 'starter') { return <Starter {...props as StarterProps} />; }
	if (props.software.type === 'library') { return <Library {...props as LibraryProps} />; }
	return null;
}
