/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useId, useState } from 'react';
import Modal from './Modal';


interface ImageSlideLink {
	isImage: boolean
	href: string
}

interface ImageSlide {
	imgs: {
		class?: string
		src: string
	}[]
	label?: string
	link: ImageSlideLink
}

interface VideoSlide {
	id?: string
	screenshot: string
	title: string
}

export interface EnonicarouselProps {
	classes: {
		img?: string
		link?: string
		list?: string
		main?: string
		modalImg?: string
		modalVideo?: string
		video?: string
	}
	screenshots: ImageSlide[]
	video?: VideoSlide
}


export default function Enonicarousel({ classes, screenshots, video }: EnonicarouselProps) {
	// SETTINGS
	const hasVideo = video && !!video.id;
	const slides = hasVideo ? [video, ...screenshots] : [...screenshots];
	const numSlides = slides.length;

	// STATES
	const [index, setIndex] = useState(0);
	const [animate, setAnimate] = useState(true);
	const [timer, setTimer] = useState<ReturnType<typeof setTimeout>|null>(null);
	const [showModal, setShowModal] = useState(false);
	const [modalBody, setModalBody] = useState<JSX.Element>();

	useEffect(() => {
		if (animate) {
			setTimer(setTimeout(() => nextSlide(), 5000));
		} else if(timer) {
			clearTimeout(timer);
			setTimer(null);
		} else {
			console.debug('Timer not set yet?');
		}
	}, [animate]);

	// HELPER FUNCTIONS
	function nextSlide() {
		const newCurrentIndex = index + 1 !== numSlides ? index + 1 : 0;
		setIndex(newCurrentIndex);

		if (animate) { setAnimate(false); setAnimate(true); }
	}

	function prevSlide() {
		const newCurrentIndex = index - 1 >= 0 ? index - 1 : numSlides - 1;
		setIndex(newCurrentIndex);
	}

	function getSlideClassName(isVideo: boolean, slideIndex: number) {
		const slideClass = slideIndex === index ? 'enonicarousel__slide--current' : '';

		if (isVideo) {
			return `${classes.main ? `${classes.main}-item ${classes.main}-item--video` : ''} enonicarousel__slide ${slideClass}`;
		}

		return `${classes.main ? `${classes.main}-item` : ''} enonicarousel__slide ${slideClass}`;
	}

	function handleVideoClick(videoID: string) {
		setShowModal(true);
		setModalBody(
			<div className={classes.modalVideo || ''}>
				<iframe
					allowFullScreen={true}
					id={`ytp${videoID}`}
					src={`https://www.youtube.com/embed/${videoID}?autoplay=1&amp;autohide=1&amp;border=0&amp;wmode=opaque&amp;enablejsapi=1`}
					title="carousel-video"
				/>
			</div>,
		);
	}

	function handleLinkClick(
		event: React.MouseEvent<HTMLAnchorElement>,
		slideLink: ImageSlideLink,
		slideLabel?: string
	) {
		event.preventDefault();

		if (slideLink.isImage) {
			setShowModal(true);
			setModalBody(
				<div className={classes.modalImg || ''}>
					<img src={slideLink.href} alt={slideLabel} />
				</div>,
			);
		} else {
			window.location.href = slideLink.href;
		}
	}

	const imageListItemKeyPrefix = useId();
	const imageKeyPrefix = useId();
	const videoListItemKeyPrefix = useId();

	// RENDER FUNCTIONS
	function renderSlides() {
		function videoToSlide(slide: VideoSlide, slideIndex: number) {
			return (
				<li
					className={getSlideClassName(true, slideIndex)}
					data-youtube-id={slide.id}
					key={`${videoListItemKeyPrefix}-${slide.id /* assuming video.id is unique and that the same video is not allowed twice in the same list */}`}
					style={{ display: index === slideIndex ? '' : 'none' }}
				>
					<img className={classes.img} src={slide.screenshot} alt="Video screenshot" />
					<button type="button" className={classes.video} data-youtube-id={slide.id} onClick={() => handleVideoClick(slide.id as string)}>
						<span className="visually-hidden">
							Play video
							{slide.title}
						</span>
					</button>
				</li>
			);
		}

		function imageToSlide(slide: ImageSlide, slideIndex: number) {
			return (
				<li
					className={getSlideClassName(false, slideIndex)}
					key={`${imageListItemKeyPrefix}-${slideIndex /* using index is not ideal */}`}
					style={index === slideIndex ? undefined : { display: 'none' }}
				>
					<a className={classes.link} onClick={(e) => handleLinkClick(e, slide.link, slide.label)}>
						{
							slide.imgs.map((img) => (
								<img
									alt={slide.label}
									className={img.class}
									key={`${imageKeyPrefix}-${img.src /* Assuming img.src is unique, aka the same image is not allowed twice in the list */}`}
									src={img.src}
								/>
							))
						}
					</a>
				</li>
			);
		}

		return (
			<ul className={`${classes.list} enonicarousel__list`}>
				{
					slides.map((slide, slideIndex) => (
						hasVideo && slideIndex === 0
							? videoToSlide(slide as VideoSlide, slideIndex)
							: imageToSlide(slide as ImageSlide, slideIndex)
					))
				}
			</ul>
		);
	}

	function renderControls() {
		return (
			<ul className="enonicarousel__ctrl">
				<li>
					<button type="button" className="enonicarousel__ctrl-btn enonicarousel__ctrl-btn--prev" onClick={() => prevSlide()}>
						<span className="enonicarousel__ctrl-btn-text">Previous slide</span>
					</button>
				</li>
				<li>
					<button type="button" className="enonicarousel__ctrl-btn enonicarousel__ctrl-btn--next" onClick={() => nextSlide()}>
						<span className="enonicarousel__ctrl-btn-text">Next slide</span>
					</button>
				</li>
			</ul>
		);
	}

	const navItemKeyPrefix = useId();
	function renderNavigation() {
		return (
			<ul className="enonicarousel__nav">
				<li className="enonicarousel__nav-item">
					<button
						type="button"
						className="enonicarousel__nav-btn enonicarousel__nav-btn--play"
						onClick={() => setAnimate(!animate)}
						data-stop={animate}
						data-start={!animate}
					>
						<span className="visually-hidden">
							{`${animate ? 'Stop' : 'Start'} Animation`}
						</span>
					</button>
				</li>
				{ slides.map((_, slideIndex) => (
					<li className="enonicarousel__nav-item" key={`${navItemKeyPrefix}-${String(slideIndex) /* using index is not ideal */}`}>
						<button
							type="button"
							className={`enonicarousel__nav-btn enonicarousel__nav-btn--slide ${slideIndex === index ? 'enonicarousel__nav-btn--current' : ''}`}
							onClick={() => setIndex(slideIndex)}
							data-slide={slideIndex + 1}
						>
							<span className="visually-hidden">Slide</span>
							<span className="enonicarousel__nav-num">{slideIndex + 1}</span>
						</button>
					</li>
				))}
			</ul>
		);
	}

	return (slides.length > 0 ? (
		<>
			<section
				className={`${classes.main} enonicarousel`}
				onMouseEnter={() => setAnimate(false)}
				onMouseLeave={() => setAnimate(true)}
			>
				<h2 className="visually-hidden">Screenshots</h2>
				{ renderSlides() }
				{ slides.length > 1 && renderControls() }
				{ slides.length > 1 && renderNavigation() }
			</section>

			<Modal maxWidth={1024} show={showModal} close={() => setShowModal(false)}>
				{modalBody}
			</Modal>
		</>
	) : null);
}
