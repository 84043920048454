import type {
	VersionNumberString,
	VersionObject,
} from '../../../../types.d';
import React, { useState } from 'react';
import Tabs from '../../Tabs';
import ClipboardIcon from '../../../../assets/img/icons/clipboard.svg';


export interface ApplicationProps {
	initialVersion: VersionObject
	software: {
		jarPlaceholderUrl: string
		name: string
		newestVersion: VersionObject
		type: 'application'//'library'
		versions: VersionObject[]
	}
	commands: {
		installAppCommands: {
			bash: string
			batch: string
			cli: string
		}
	}
}


export default function Application({ initialVersion, software, commands }: ApplicationProps) {
	const [selectedVersion, setSelectedVersion] = useState(initialVersion);

	// HELPER FUNCTIONS
	function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
		const selectedVersionNumber = e.target.value as VersionNumberString;
		setSelectedVersion(prev => {
			const maybeVersionObject = software.versions.find((version) => version.versionNumber === selectedVersionNumber);
			return maybeVersionObject || prev;
		});
	}

	function copyToClipboard(inputID: string) {
		const element = document.getElementById(inputID) as HTMLInputElement;

		const value = element ? element.value : '';

		if (value) {
			navigator.clipboard.writeText(value);
			element.focus();
		}
	}

	function isSupportedVersionGreaterThan(supportedVersions: VersionNumberString[], majorVersionNumber: number) {
		const versions = ([] as VersionNumberString[]).concat(supportedVersions);

		if (versions.length > 1) {
			return versions.every((version) => parseInt(version.split('.')[0]) >= majorVersionNumber);
		}

		if (versions.length === 1) {
			return parseInt(versions[0].split('.')[0]) >= majorVersionNumber;
		}

		return false;
	}

	function displaySupportedVersions(supportedVersions: VersionNumberString[]) {
		const versions = ([] as VersionNumberString[]).concat(supportedVersions);

		if (versions.length > 1) {
			return versions.map((v, i) => `${i > 0 ? ', ' : ''} ${v}`);
		}

		if (versions.length === 1) {
			return versions[0];
		}

		return '';
	}

	function replaceVersionPlaceholder(string: string, versionNumber: VersionNumberString) {
		return string.replaceAll('VERSIONPLACEHOLDER', versionNumber);
	}

	// TAB ITEMS
	const installApp = (
		<div>
		<p>
			This version requires XP
			{' '}
			<span className="requiredVersion">{displaySupportedVersions(selectedVersion.supportedVersions)}</span>
		</p>
		<p>
			To install and run this app, you will need a running instance of
			{' '}
			<a href="https://enonic.com">Enonic XP</a>
			. The app can be installed in several ways:
		</p>

		<div>
			<div id="using-xp-admin">
			<section className="software-show__install-app-section software-show__install-app-section--xp">
				<h3 className="software-show__install-app-heading">Using XP Admin</h3>
				<p>Applications from Enonic Market can be installed directly from the "Applications" app in Enonic XP.</p>
				<p>
				<a href="https://xp.readthedocs.io/en/stable/admin/applications/index.html#installing-an-app">'Installing an application' documentation</a>
				</p>
			</section>
			</div>

			<p>OR</p>

			<div id="using-cli">
			<section className="software-show__install-app-section software-show__install-app-section--xp">
				{ isSupportedVersionGreaterThan(selectedVersion.supportedVersions, 7) && commands.installAppCommands && commands.installAppCommands.cli
					&& (
					<div className="software-show__download-instructions-body versionSevenCloneMsg">
					<h3 className="software-show__install-app-heading">Using CLI</h3>
					<p>Run the following command:</p>
					<div className="software-show__toolbox-code software-show__toolbox-code">
						<h3 className="software-show__toolbox-code-heading">$</h3>
						<div className="software-show__toolbox-code-command">
						<input
							id="software-show__toolbox-code-command-input--app-cli"
							className="software-show__toolbox-code-command-input"
							value={replaceVersionPlaceholder(commands.installAppCommands.cli, selectedVersion.versionNumber)}
						/>
						</div>
						<div className="software-show__toolbox-code-clipboard-btn-container">
						<button
							type="button"
							className="software-show__toolbox-code-clipboard-btn"
							onClick={() => copyToClipboard('software-show__toolbox-code-command-input--app-cli')}
						>
							<div className="software-show__toolbox-code-clipboard-btn-icon">
							<ClipboardIcon viewBox="0 0 1792 1792" />
							</div>
							<span>Copy</span>
						</button>
						</div>
					</div>
					<p>
						This command installs an application on all nodes. Remember to replace
						{' '}
						<strong>&lt;username&gt;</strong>
						{' '}
						and
						{' '}
						<strong>&lt;password&gt;</strong>
						{' '}
						with your admin login credentials.
					</p>
					<p><a href="https://developer.enonic.com/docs/enonic-cli/master">Enonic CLI documentation</a></p>
					</div>
					)}

				{ !isSupportedVersionGreaterThan(selectedVersion.supportedVersions, 7)
					&& (
					<div className="software-show__download-instructions-body notVersionSevenCloneMsg">
					<h3 className="software-show__install-app-heading">Using Toolbox</h3>
						{ commands.installAppCommands && commands.installAppCommands.bash
							&& (
							<div className="software-show__toolbox-code software-show__toolbox-code--bash">
							<h4 className="software-show__toolbox-code-heading">OSX/Linux</h4>
							<div className="software-show__toolbox-code-command">
								<input
								id="software-show__toolbox-code-command-input--app-bash"
								className="software-show__toolbox-code-command-input"
								value={replaceVersionPlaceholder(commands.installAppCommands.bash, selectedVersion.versionNumber)}
								/>
							</div>
							<div className="software-show__toolbox-code-clipboard-btn-container">
								<button
									type="button"
									className="software-show__toolbox-code-clipboard-btn"
									onClick={() => copyToClipboard('software-show__toolbox-code-command-input--app-bash')}
								>
								<div className="software-show__toolbox-code-clipboard-btn-icon">
									<ClipboardIcon viewBox="0 0 1792 1792" />
								</div>
								<span>Copy</span>
								</button>
							</div>
							</div>
							)}

						{ commands.installAppCommands && commands.installAppCommands.batch
							&& (
							<div className="software-show__toolbox-code software-show__toolbox-code--batch">
							<h4 className="software-show__toolbox-code-heading">Windows</h4>
							<div className="software-show__toolbox-code-command">
								<input
								id="software-show__toolbox-code-command-input--app-batch"
								className="software-show__toolbox-code-command-input"
								value={replaceVersionPlaceholder(commands.installAppCommands.batch, selectedVersion.versionNumber)}
								/>
							</div>
							<div className="software-show__toolbox-code-clipboard-btn-container">
								<button
									type="button"
									className="software-show__toolbox-code-clipboard-btn"
									onClick={() => copyToClipboard('software-show__toolbox-code-command-input--app-batch')}
								>
								<div className="software-show__toolbox-code-clipboard-btn-icon">
									<ClipboardIcon viewBox="0 0 1792 1792" />
								</div>
								<span>Copy</span>
								</button>
							</div>
							</div>
							)}
					<p>
						The install-app tool installs an application on all nodes. Remember to replace
						{' '}
						<strong>&lt;username&gt;</strong>
						{' '}
						and
						{' '}
						<strong>&lt;password&gt;</strong>
						{' '}
						with your admin login credentials.
					</p>
					<p>
						<a href="https://xp.readthedocs.io/en/stable/developer/projects/deploy.html">
						Complete install-app documentation
						</a>
					</p>
					</div>
					)}
			</section>
			</div>
		</div>
		</div>
	);

	const downloadApp = (
		<div>
		<section className="software-show__install-app-section software-show__install-app-section--jar">
			<h3 className="software-show__install-app-heading">Download JAR</h3>
			{ software.jarPlaceholderUrl
				&& (
				<a
				className="software-show__download-instructions-download-btn"
				href={replaceVersionPlaceholder(software.jarPlaceholderUrl, selectedVersion.versionNumber)}
				>
				<span>
					Download
					{' '}
					{software.name}
					{' '}
					v
					{selectedVersion.versionNumber}
				</span>
				</a>
				)}
		</section>
		</div>
	);

	const tabSettings = {
		menuItems: [
		{
			id: 'install-app',
			title: 'Install',
		},
		{
			id: 'download-app',
			title: 'Download',
		},
		],
		contentItems: [
		{
			id: 'install-app',
			content: installApp,
		},
		{
			id: 'download-app',
			content: downloadApp,
		},
		],
	};

	return (
		<div className="software-show__download-instructions">
		<h2 className="software-show__download-instructions-heading">
			Get
			{' '}
			{software.name}
		</h2>

		{ software.versions && (
			<select
				className="software-show__version-select"
				onChange={handleSelect}
			>
				{ software.versions.map((version) => (
				<option
					selected={selectedVersion.versionNumber === version.versionNumber}
					value={version.versionNumber}
				>
					v.
					{version.versionNumber}
					{version.versionNumber === software.newestVersion.versionNumber ? ' - latest' : '' }
				</option>
				))}
			</select>
		)}

		<div className="software-show__download-instructions-body">
			<div className="software-show__download-instructions-tabs">
			<Tabs
				initialTabId="install-app"
				menuItems={tabSettings.menuItems}
				contentItems={tabSettings.contentItems}
			/>
			</div>
		</div>
		</div>
	);
}
