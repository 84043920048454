// regenerator-runtime is a "polyfill" for async await.
// Graal-JS and all modern browsers support async await.
// https://caniuse.com/?search=async%20await
// Internet Explorer and Nashorn don't, but React4xp don't support those anymore :)
//
// It might still be possible to support IE, if one polyfills only in the browser
// <script nomodule src="https://cdn.jsdelivr.net/npm/regenerator-runtime@0.13.5/runtime.js"></script>
// import regeneratorRuntime from "regenerator-runtime";
import React, { useState } from "react";


export interface SearchProps {
	initialClass: string
	show: boolean
	icon?: string
	inputPlaceholder?: string
	inputInitialValue: string
	resultsPage: string
	serviceUrl: string
}

interface Hit {
	active: boolean
	name: string
	url: string
}


export default function Search({
	initialClass,
	show,
	icon,
	inputPlaceholder,
	inputInitialValue,
	resultsPage,
	serviceUrl,
}: SearchProps) {
	const [searchTerm, setSearchTerm] = useState(inputInitialValue);
	const [searchResults, setSearchResults] = useState<Hit[]>([]);
	const [inputFocus, setInputFocus] = useState(false);

	/**
	 * Get the hits from the serviceurl based on a search term.
	 */
	async function getResults(term: string) {
		const response = await fetch(`${serviceUrl}?q=${term}`);
		if (response.status !== 200) {
			throw response.statusText;
		}
		return (await response.json()).hits as Hit[];
	}

	/**
	 * Triggered on input change.
	 */
	async function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
		setSearchTerm(e.target.value.toLowerCase());
		setSearchResults(await getResults(searchTerm));
	}

	/**
	 * Triggered on input key down.
	 */
	function handleInputKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
		const [enter, up, down, tab, shiftTab, esc] = [
			e.keyCode === 13,
			e.keyCode === 40,
			e.keyCode === 38,
			e.keyCode === 9,
			e.shiftKey && e.keyCode === 9,
			e.keyCode === 27,
		];
		if (enter) {
			gotoLink(e);
		} else if (down || shiftTab) {
			prev();
		} else if (up || tab) {
			next();
		} else if (esc) {
			close();
		}

		if ((tab || shiftTab) && searchResults.length > 0) {
			e.preventDefault();
		}
	}

	/**
	 * Go to the link of the current active element.
	 * @param {Event} e
	 */
	function gotoLink(e: React.KeyboardEvent<HTMLInputElement>) {
		const activeResult = searchResults.find((result) => result.active);
		if (activeResult) {
			e.preventDefault();
			window.location.href = activeResult.url;
		}
	}

	/**
	 * Set current searchResults element with active from true to false.
	 * Set active to true, if possible, of the next element in the searchResults.
	 */
	function next() {
		const idx = searchResults.findIndex((result) => result.active) || 0;

		if (idx + 1 < searchResults.length) {
			const updatedSearchResults = searchResults.map((result, i) => {
				if (i === idx) result.active = false;
				if (i === idx + 1) result.active = true;
				return result;
			});

			setSearchResults(updatedSearchResults);
		}
	}

	/**
	 * Set current searchResults element with active from true to false.
	 * Set active to false, if possible, of the previous element in the searchResults.
	 */
	function prev() {
		const idx = searchResults.findIndex((result) => result.active) || 0;

		if (idx - 1 >= 0) {
			const updatedSearchResults = searchResults.map((result, i) => {
				if (i === idx) result.active = false;
				if (i === idx - 1) result.active = true;
				return result;
			});

			setSearchResults(updatedSearchResults);
		}
	}

	/**
	 * Close the menu by changing state inputFocus to false.
	 * Quick callback is used to avoid the incorrect behavior of the redirect click on the result link.
	 */
	function close() {
		// setTimeout(() => setInputFocus(false), 100);
		setInputFocus(false);
	}

	/**
	 * Display the results.
	 * @returns HTML
	 */
	function DisplayResults() {
		const getHightlightedName = (name: string) =>
			name.replace(new RegExp(searchTerm, "gi"), (s) => `<b>${s}</b>`);

		if (inputFocus) {
			if (searchResults.length > 0) {
				return (
					<ul
						className={`${initialClass}search-result live-search__result`}
					>
						{searchResults.map((result, index) => (
							<li
								key={index}
								className={`live-search__hit${result.active ? "--active" : ""}`}
								onFocus={() => setInputFocus(true)}
								data-value={result.name}
							>
								<a
									className="live-search__hit-link"
									href={result.url}
									onMouseDown={(event) => event.preventDefault()}
								>
									<h4
										className="live-search__hit-heading"
										dangerouslySetInnerHTML={{
											__html: getHightlightedName(result.name),
										}}
									/>
								</a>
							</li>
						))}
					</ul>
				);
			}
			return (
				searchTerm ? (
					<div className="live-search__no-results">No results</div>
				) : null
			);
		}
		return <div />;
	}

	return (
		show ? (
			<form
				className={`${initialClass}search-form live-search__form`}
				method="GET"
				role="search"
				action={resultsPage}
				data-live-search-url={serviceUrl}
				onBlur={close}
			>
				<div
					className={`${initialClass}search-box live-search__search-box`}
					role="search"
				>
					{icon && (
						<img
							className={`${initialClass}search-icon`}
							src={icon}
							alt="search icon"
						/>
					)}
					<input
						onFocus={() => setInputFocus(true)}
						onChange={handleInputChange}
						onKeyDown={handleInputKeyDown}
						className={`${initialClass}search-input live-search__input`}
						value={searchTerm}
						placeholder={inputPlaceholder || ""}
						type="text"
						name="q"
						autoComplete="off"
					/>
				</div>

				<DisplayResults />
			</form>
		) : null
	);
}
