import type {
	VersionNumberString,
	VersionObject,
} from '../../../../types.d';
import React, { useState } from 'react';


export interface LibraryProps {
	initialVersion: VersionObject
	software: {
		artifactId: string
		documentationUrl: string
		groupId: string
		mavenRepository: string
		name: string
		newestVersion: VersionObject
		sourceCodeUrl: string
		type: 'library'
		versions: VersionObject[]
	}
}


export default function Library({ initialVersion, software }: LibraryProps) {
	const [selectedVersion, setSelectedVersion] = useState(initialVersion);

	function handleSelect(e: React.ChangeEvent<HTMLSelectElement>) {
		const selectedVersionNumber = e.target.value;
		setSelectedVersion(prev => {
			const maybeVersionObject = software.versions.find((version) => version.versionNumber === selectedVersionNumber);
			return maybeVersionObject || prev;
		});
	}

	function displaySupportedVersions(supportedVersions: VersionNumberString[]) {
		const versions = ([] as VersionNumberString[]).concat(supportedVersions);

		if (versions.length > 1) {
			return versions.map((v, i) => `${i > 0 ? ', ' : ''} ${v}`);
		}

		if (versions.length === 1) {
			return versions[0];
		}

		return '';
	}

	return (
		<div className="software-show__download-instructions">
			<h2 className="software-show__download-instructions-heading">
				{`Download ${software.name}`}
			</h2>

			{ software.versions && (
				<select
					className="software-show__version-select"
					onChange={handleSelect}
				>
					{ software.versions.map((version) => (
					<option value={version.versionNumber} selected={selectedVersion.versionNumber === version.versionNumber}>
						v.
						{version.versionNumber}
						{version.versionNumber === software.newestVersion.versionNumber ? ' - latest' : '' }
					</option>
					))}
				</select>
			)}

			<div className="software-show__download-instructions-body">
				<p className="libRequiredVersion">
				This version requires XP
				{' '}
				<span className="version">{ displaySupportedVersions(selectedVersion.supportedVersions) }</span>
				</p>

				<p>
				An XP library is normally included in your application through the Gradle build script like this:
				</p>

				{ software.groupId && software.artifactId
					&& (
					<pre className="language-clike">
					<code className="software-show__download-instructions-gradle-code language-clike">
						dependencies
						{' '}
						<span className="token punctuation">{'{'}</span>
						{' '}
						<br />
						&nbsp;&nbsp;
						include
						{' '}
						<span className="token string">
						'
						{`${software.groupId}:${software.artifactId}:${selectedVersion.versionNumber}`}
						'
						</span>
						{' '}
						<br />
						<span className="token punctuation">{'}'}</span>
					</code>
					</pre>
					)}

				{ software.mavenRepository
					&& (
					<pre className="language-clike">
					<code className="software-show__download-instructions-gradle-code language-clike">
						repositories
						{' '}
						<span className="token punctuation">{'{'}</span>
						{' '}
						<br />
						&nbsp;&nbsp;
						maven
						{' '}
						<span className="token punctuation">{'{'}</span>
						{' '}
						<br />
						&nbsp;&nbsp;&nbsp;&nbsp;
						url
						{' '}
						<span className="token string">
						'
						{software.mavenRepository}
						'
						</span>
						{' '}
						<br />

						{' '}
						&nbsp;&nbsp;
						<span className="token punctuation">{'}'}</span>
						{' '}
						<br />
						<span className="token punctuation">{'}'}</span>
					</code>
					</pre>
					)}

				{ software.sourceCodeUrl
					&& (
					<p>
					<a href={software.sourceCodeUrl}>
						Download the source code for
						{software.name}
					</a>
					</p>
					)}

				{ software.documentationUrl
					&& (
					<p>
					<a href={software.documentationUrl}>
						Documentation for
						{software.name}
					</a>
					</p>
					)}
			</div>
		</div>
	);
}
