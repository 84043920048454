import React, { useId } from 'react';


export interface Product {
	description: string
	icon: string
	name: string
	url: string
}

interface ProductListingProps {
	products: Product[]
}


export default function ProductListing({products}: ProductListingProps) {
	const prefix = useId();
	return (
		<div className="product-listing">
			<div className="product-listing__list">
				<ul>
					{
						products.map((product, i) => (
							<li className="product-listing__item" key={`${prefix}-${i /* using index is not ideal */}`}>
								<article className="product-listing__product">
									<a className="product-listing__product-link" href={product.url}>
										<img className="product-listing__product-icon" src={product.icon} alt={`${product.name} icon`} />
										<h2 className="product-listing__product-heading">{product.name}</h2>
									</a>
									<p className="product-listing__product-subtitle">{product.description}</p>
								</article>
							</li>
						))
					}
				</ul>
			</div>
		</div>
	);
}
