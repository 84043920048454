/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';

// const { useEffect, useRef, useState } = React;


interface TabsProps {
	initialTabId: string
	menuItems: {
		id: string
		title: string
	}[]
	contentItems: {
		content: JSX.Element
		id: string
	}[]
}

export default function Tabs(props: TabsProps) {
	const { initialTabId, menuItems, contentItems } = props;

	const menuItemsRef = useRef<HTMLUListElement>(null);
	const [selectedTabId, setSelectedTabId] = useState(initialTabId);
	const [indicatorStyle, setIndicatorStyle] = useState({});

	const handleIndicator = () => {
		const liItems = (menuItemsRef.current ? [...menuItemsRef.current.children] : []) as HTMLLIElement[];
		const selectedItem = liItems.find((el) => el.className === 'tabs__menu-item--selected');
		// eslint-disable-next-line react/no-find-dom-node
		const { offsetLeft } = (
			selectedItem
				? ReactDOM.findDOMNode(selectedItem)
				: { offsetLeft: 0 }
		) as HTMLLIElement;
		const { width } = selectedItem ? window.getComputedStyle(selectedItem) : { width: 0 };
		setIndicatorStyle({ left: offsetLeft, width });
	};

	useEffect(handleIndicator, [selectedTabId]);

	return (
		<div className="tabs">
			<div className="tabs__menu">
				<ul ref={menuItemsRef}>
				{ menuItems.map((menuItem) => (
					<li
						tabIndex={0}
						className={`tabs__menu-item${selectedTabId === menuItem.id ? '--selected' : ''}`}
						onClick={() => setSelectedTabId(menuItem.id)}
						onKeyDown={(e) => e.key === 'Enter' && setSelectedTabId(menuItem.id)}
					>
					{ menuItem.title }
					</li>
				))}
				</ul>
				<div className="tabs__indicator" style={indicatorStyle} />
			</div>
			<div className="tabs__panel">
				{ contentItems.map((contentItem) => {
					if (contentItem.id === selectedTabId) {
						return contentItem.content;
					}
					return <div />;
				})}
			</div>
		</div>
	);
}
