import React from 'react';


export interface LogoProps {
	title: string
	url: string
}


export default function ({
	title,
	url
}: LogoProps) {
	const logoTitle = title || 'Enonic Market';
	const logoUrl = url;

	return (
		<a className="page-default__header-logo-link" title="Frontpage" href={logoUrl}>
			<svg className="page-default__header-logo-img" viewBox="0 0 1413 433" xmlns="http://www.w3.org/2000/svg">
				<title>{ logoTitle }</title>
				<g fill="none" fillRule="evenodd">
				<g className="page-default__header-logo-enonic" fill="#000">
					<path d="m0 118.44c0-65.48 47.02-118.44 105.61-118.44 55.892 0 100.27 48.15 104.7 109.77v1.915c0 6.248-4.876 10.582-10.635 10.582h-178.38c2.206 51.52 39.498 90.52 84.31 90.52 28.84 0 55.45-16.377 70.08-42.856 3.112-5.77 9.776-6.727 14.211-3.855 4.9 3.855 6.664 10.607 3.553 16.377-18.646 32.25-51.04 53.44-87.84 53.44-58.59 0-105.61-52.959-105.61-117.46m22.617-18.77h164.63c-7.106-44.32-41.727-76.57-81.64-76.57-40.822 0-75 31.796-82.991 76.57" />
					<path d="m810.32 0c-51.29 0-92.8 43.42-92.8 96.01v128.33c0 6.741 5.124 11.563 11.176 11.563 6.076 0 11.2-4.822 11.2-11.563v-128.33c0-40.04 31.23-72.86 70.42-72.86 39.16 0 70.4 32.82 70.4 72.86v128.33c0 6.741 5.124 11.563 11.2 11.563 6.05 0 11.176-4.822 11.176-11.563v-128.33c0-52.587-41.481-96.01-92.77-96.01" />
					<path d="m340.63 0c-51.29 0-92.8 43.42-92.8 96.01v128.33c0 6.741 5.124 11.563 11.176 11.563 6.076 0 11.2-4.822 11.2-11.563v-128.33c0-40.04 31.23-72.86 70.42-72.86 39.16 0 70.4 32.82 70.4 72.86v128.33c0 6.741 5.124 11.563 11.2 11.563 6.05 0 11.176-4.822 11.176-11.563v-128.33c0-52.587-41.481-96.01-92.77-96.01" />
					<path d="m940.21 11.564c0-6.752 5.196-11.564 11.357-11.564 6.606 0 11.802 4.812 11.802 11.564v212.79c0 6.248-5.196 11.539-11.802 11.539-6.161 0-11.357-5.291-11.357-11.539v-212.79" />
					<path d="m1002.06 117.48c0-65.48 53.19-117.48 119.69-117.48 27.541 0 53.691 9.624 74.1 26 4.748 3.376 5.692 10.582 1.442 15.873-3.828 4.333-10.465 5.795-15.685 1.94-16.629-13.479-37.534-21.19-59.855-21.19-54.14 0-96.89 42.856-96.89 94.86 0 52.934 42.754 95.31 96.89 95.31 22.321 0 43.23-7.71 59.855-20.71 5.22-3.855 11.857-2.394 15.685 1.94 4.251 5.291 3.306 12.522-2.386 16.855-19.463 15.394-45.612 25.02-73.15 25.02-66.49 0-119.69-52-119.69-118.42" />
					<path d="m682 118c0-65.17-47.682-118-106.5-118-58.818 0-106.5 52.83-106.5 118h22c.243-51.41 37.982-93 84.5-93 46.517 0 84.26 41.591 84.5 93h22" />
					<path d="m459.65 118h232.69c5.738 0 10.05 4.487 9.624 9.997l-10.939 143.01c-.422 5.521-4.951 9.997-10.132 9.997h-209.8c-5.173 0-9.71-4.487-10.132-9.997l-10.939-143.01c-.422-5.521 3.879-9.997 9.624-9.997m222.35 0c0 65.17-47.682 118-106.5 118-58.818 0-106.5-52.83-106.5-118h22c-.001.167-.001.333-.001.5 0 51.639 37.832 93.5 84.5 93.5 46.668 0 84.5-41.861 84.5-93.5 0-.167 0-.333-.001-.5h22" />
				</g>
				<path d="m149.6 68.8c0-15.8-9.6-26.4-28.2-26.4-15.8 0-29.6 10.4-36.2 21.6-.2-5-2.6-10.6-6.2-14.2-4.6-4.6-11.6-7.4-21.2-7.4-13.8 0-26.6 9.4-33.2 19.8l2-17.2h-8.8l-17.8 100h10l9-50c2.6-14.2 8-27.6 18.4-36 5.2-4.2 11.2-7.2 18.8-7.2 14 0 19.6 7.6 19.6 18.8 0 2.8-.2 5.8-.8 9l-11.4 65.4h10l9.6-53.8c2.4-13.8 8.6-24.8 17.6-32 5.4-4.2 11-7.4 19-7.4 13.2 0 19.6 6.4 19.6 18.8 0 2.8-.4 5.8-1 9l-11.4 65.4h10l11.6-66c.6-3.6 1-7 1-10.2m116-23.8h-8.8l-4.8 17c-2.8-8-13.4-19.6-29.4-19.6-12.6 0-23.6 5.2-31.8 13.4-12.2 12.6-18 31.8-18 49.6 0 10.4 2.2 19.8 7.4 27.2 6.2 9 17 15 29 15 13.8 0 27.2-9 32.2-19.8l-1.8 17.2h8.2l17.8-100m-18 40c0 14.2-5.4 32.2-16.4 43.2-5.6 5.6-12.6 9.6-21 9.6-11 0-18.2-4.8-22.4-12-3.8-6.4-5-13-5-21.4 0-15.4 5.2-32.4 15.2-42.4 5.8-6 14.8-9.8 23.2-9.8 10 0 16.4 3.8 20.8 9.8 4.6 6.4 5.6 13.6 5.6 23m108.4-39.8c-3.2-.6-6.4-1.2-9.6-1.2-14.6 0-29 7-35.6 20.4l2.4-19.4h-8.8l-18 100h9.8l9.2-49.6c3.6-19.8 16.2-41.8 39.2-41.8 3.2 0 6.4.8 9.6 1.6l1.8-10m103.2-.2h-13.6l-65.8 59.6 18.4-104h-10.2l-25.6 144.4h10.2l4.4-24.8 31.8-29 25.8 53.8h11.6l-29.2-61.4 42.2-38.6m94.4 39.2c0-12.6-4-23.4-11.6-31-6.4-6.4-15.4-10.8-26.8-10.8-14.2 0-24.6 5.8-32.2 13.4-12.4 12.6-17.4 30.4-17.4 48 0 14 4 24.4 11.6 32.4 6.6 7 15.8 11.4 27.8 11.4 11.2 0 22.2-4.2 30.4-12 4.4-4.2 9.8-12 11-18h-10.6c-4.4 11.6-17 20.2-29.8 20.2-9.4 0-16.6-3-21.6-8.2-6-6.4-9-15.8-9-26.4 0-1.8 0-3.8.2-5.8h76.4c1-5.8 1.6-7.4 1.6-13.2m-10-.2c0 1.4 0 2.8-.2 4.2h-66.4c1.8-8.8 5.6-17.4 10.8-23.4 6.2-7 14.6-12.8 26.2-12.8 19 0 29.6 14 29.6 32m91.4-39h-31l5.4-30.4-10.4 2.8-5 27.6h-20l-1.6 9.2h20l-11.2 63c-.6 2.8-.8 5.6-.8 8.6 0 13.8 10 20.6 22.8 20.6 5.8 0 13.8-2.2 18.2-4.6l1.8-9.6c-4.6 2-12.6 4.8-18.4 4.8-8.6 0-14.2-3.6-14.2-12.8 0-2.4.4-5 .8-7.4l11-62.6h31l1.6-9.2" transform="translate(562 285)" fill="#39a5c8" />
				</g>
			</svg>
			<span className="page-default__header-logo-text">{ logoTitle }</span>
		</a>
	);
}
