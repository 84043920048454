import type { VersionNumberString } from '../../../../types.d';
import React, {useId} from 'react';


export interface ChangelogProps {
	changelog: {
		versions: {
			changelogEntry: string[]
			supportedVersions: VersionNumberString[]
			versionDate: string
			versionNumber: string
			versionDatePrettified: string
		}[]
	}
}


export default function Changelog({ changelog }: ChangelogProps) {
	const versionItemPrefix = useId();
	const changelogItemPrefix = useId();
	const supportedVersionItemPrefix = useId();
	return (
		<section className="software-show__changelog">
			<h2>Releases</h2>
			<ol className="software-show__changelog-version-list">
				{ changelog.versions.map((version, i) => (
				<li
					className="software-show__changelog-version-item"
					key={`${versionItemPrefix}-${version.versionNumber /* Assuming versionNumber is uniq */}`}
				>

					<header className="software-show__changelog-version-header">
					<h3 className="software-show__changelog-version-heading">
						{version.versionNumber}
					</h3>
					{/* eslint-disable-next-line react/no-unknown-property */}
					<time className="software-show__changelog-version-date" dateTime={version.versionDate}>
						{version.versionDatePrettified}
					</time>
					</header>

					<ul className="software-show__changelog-entry-list">
					{ ([] as string[]).concat(version.changelogEntry).map((changelogEntry, i) => (
						<li
							className="software-show__changelog-entry-item"
							key={`${changelogItemPrefix}-${i /* Using index is not ideal */}`}
						>
							{changelogEntry}
						</li>
					))}

					{ version.supportedVersions && (
					<li className="software-show__compatibility">
						<h4 className="software-show__compatibility-heading" title="Minimum required XP version (per major version)">Compatibility:</h4>
						<ul className="software-show__compatibility-list">
						{ ([] as VersionNumberString[]).concat(version.supportedVersions).map((supportedVersion) => (
							<li className="software-show__compatibility-item" key={`${supportedVersionItemPrefix}-${supportedVersion /* Assuming supportedVersion uniq */}`}>
								{supportedVersion}
							</li>
						))}
						</ul>
					</li>
					)}
					</ul>
				</li>
				))}
			</ol>
		</section>
	);
}
