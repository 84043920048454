import type { MenuProps } from "../../../types.d";
import React, { useState, useEffect } from 'react';
import LogoEnonic from '../../../assets/img/logo-enonic.svg';
import LogoEnonicDiscuss from '../../../assets/img/logo-enonic-discuss.svg';
import LogoEnonicSupport from '../../../assets/img/logo-enonic-support.svg';


function MenuMobile({ menuItems }: MenuProps) {
	const [show, setShow] = useState(false);

	// EFFECTS
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		show
		? document.body.classList.add('main-nav-toggled')
		: document.body.classList.remove('main-nav-toggled');
	}, [show]);

	useEffect(() => {
		const handleEsc = (e: KeyboardEvent) => {
		// eslint-disable-next-line no-unused-expressions
		e.code === 'Escape' && setShow(false);
		};

		document.body.addEventListener('keydown', handleEsc);
		return () => { document.body.removeEventListener('keydown', handleEsc); };
	}, []);

	return (
		<>
			<button
				type="button"
				className="page-default__main-menu-trigger"
				id="page-default__main-menu-trigger"
				aria-label={`${!show ? 'open' : 'close'} menu`}
				aria-owns="page-default__main-menu"
				aria-controls="page-default__main-menu"
				aria-expanded={show}
				onClick={() => setShow(!show)}
			>
				<span className="page-default__main-menu-trigger-icon" />
			</button>
			{ show && (
				<div className="page-default__main-menu" id="page-default__main-menu" aria-expanded="true" aria-labelledby="page-default__main-menu-trigger">
					{/* NAV */}
					<nav className="page-default__main-menu-nav">
						<ul className="page-default__main-menu-nav-list">
						{
							menuItems.map(({
								title,
								url
							}) => (
							<li className="page-default__main-menu-nav-item">
								<a className="page-default__main-menu-nav-link" href={url}>
									{title}
								</a>
							</li>
							))
						}
						</ul>
					</nav>

					{/* SITES */}
					<div className="page-default__enonic-sites">
						<ul className="page-default__enonic-sites-list">
						<li className="page-default__enonic-sites-item">
							<a className="page-default__enonic-sites-link" href="https://enonic.com">
							<LogoEnonic viewBox="0 0 1200 481" />
							</a>
						</li>
						<li className="page-default__enonic-sites-item">
							<a className="page-default__enonic-sites-link" href="https://discuss.enonic.com">
							<LogoEnonicDiscuss viewBox="0 0 1200 481" />
							</a>
						</li>
						<li className="page-default__enonic-sites-item">
							<a className="page-default__enonic-sites-link" href="http://support.enonic.com">
							<LogoEnonicSupport viewBox="0 0 1200 481" />
							</a>
						</li>
						</ul>
					</div>
				</div>
			)}
		</>
	);
}


export default (props: MenuProps) => <MenuMobile {...props} />;

