import type { MenuProps } from '../../../types.d';
import React from 'react';
import Logo, {type LogoProps} from './Logo';
import Menu from './Menu';
import OurSites from './OurSites';
import Search, {type SearchProps} from '../Search';
import MenuMobile from './MenuMobile';


export interface HeaderProps {
	logo: LogoProps
	menu: MenuProps
	search: SearchProps
}


export default function Header({
	logo,
	menu,
	search
}: HeaderProps) {
	return (
		<header className="page-default__header">
			<nav className="page-default__header-container">
				<Logo {...logo} />
				<Menu {...menu} />
				<div style={{ display: 'flex' }}>
				<OurSites />
				<Search {...search} />
				<MenuMobile {...menu} />
				</div>
			</nav>
		</header>
	);
}

// export default (props: HeaderProps) => <Header {...props} />;
