import type { VersionObject } from '../../../../types.d';
import React from 'react';
import GavelIcon from '../../../../assets/img/icons/gavel.svg';
import BookIcon from '../../../../assets/img/icons/book.svg';
import ForkIcon from '../../../../assets/img/icons/code-fork.svg';
import ClockIcon from '../../../../assets/img/icons/clock-o.svg';
import UserIcon from '../../../../assets/img/icons/user.svg';


export interface MetadataProps {
	metadata: {
		software: {
			documentationUrl?: string
			newestVersion?: VersionObject & { versionDatePrettified: string }
			licenseType: {
				name: string
				url?: string
			}
			sourceCodeLink: string
		}
		vendor?: {
			name: string
			url: string
		}
	}
}

export default function Metadata({ metadata }: MetadataProps) {
	return (
		<div className="software-show__metadata-bar">
			<div className="software-show__metadata-list-container">
				<ul className="software-show__metadata-list">

				{ metadata.software.licenseType && metadata.software.licenseType.name && (
					<li className="software-show__metadata-item software-show__metadata-item--license">
						<div className="software-show__metadata-icon"><GavelIcon viewBox="0 0 1792 1792" /></div>
						<span className="software-show__metadata-label">License: </span>
						<a className="software-show__metadata-text" href={metadata.software.licenseType.url || '#'}>
							<span className="software-show__metadata-text--normal">{ metadata.software.licenseType.name }</span>
							<span className="software-show__metadata-text--short">{ metadata.software.licenseType.name }</span>
						</a>
					</li>
				)}

				{ metadata.software.documentationUrl && (
					<li className="software-show__metadata-item software-show__metadata-item--doc-link">
						<div className="software-show__metadata-icon"><BookIcon viewBox="0 0 1792 1792" /></div>
						<a className="software-show__metadata-text" href={metadata.software.documentationUrl}>
							<span className="software-show__metadata-text--normal">Documentation</span>
							<span className="software-show__metadata-text--short">Docs</span>
						</a>
					</li>
				)}

				{ metadata.software.newestVersion && (
					<li className="software-show__metadata-item software-show__metadata-item--version">
						<div className="software-show__metadata-icon"><ForkIcon viewBox="0 0 1792 1792" /></div>
						<span className="software-show__metadata-label">Version: </span>
						<a className="software-show__metadata-text" href={metadata.software.sourceCodeLink}>
							<span className="software-show__metadata-text--normal">{metadata.software.newestVersion.versionNumber}</span>
							<span className="software-show__metadata-text--short">{metadata.software.newestVersion.versionNumber}</span>
						</a>
					</li>
				)}

				{ metadata.software.newestVersion && (
					<li className="software-show__metadata-item software-show__metadata-item--date">
						<div className="software-show__metadata-icon"><ClockIcon viewBox="0 0 1792 1792" /></div>
						<span className="software-show__metadata-label">Date: </span>
						<span className="software-show__metadata-text">
							<span className="software-show__metadata-text--normal">{metadata.software.newestVersion.versionDatePrettified}</span>
							<span className="software-show__metadata-text--short">{metadata.software.newestVersion.versionDatePrettified}</span>
						</span>
					</li>
				)}

				{ metadata.vendor && (
					<li className="software-show__metadata-item software-show__metadata-item--vendor">
						<div className="software-show__metadata-icon"><UserIcon viewBox="0 0 1792 1792" /></div>
						<span className="software-show__metadata-label">By: </span>
						<a className="software-show__metadata-text" href={metadata.vendor.url}>
							<span className="software-show__metadata-text--normal">{metadata.vendor.name}</span>
							<span className="software-show__metadata-text--short">{metadata.vendor.name}</span>
						</a>
					</li>
				)}

				</ul>
			</div>
		</div>
	);
}
